.not-found-page {
    /* Set the image as background */
    background-image: url('../images/NotFoundPage.png');
  
    /* Ensure it covers the entire viewport */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  
    /* Set the height of the container to cover the entire viewport */
    height: 100vh;
  }
  